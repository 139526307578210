import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { ClassicButtonComponent } from '@examdojo/core/button';
import { FeatureFlagDirective } from '@examdojo/core/feature-flag';
import { IconComponent } from '@examdojo/core/icon';
import { provideFaIcons } from '@examdojo/icons';
import { ExamDojoFeatureFlag } from '@examdojo/models/feature-flag';
import { AuthService, ProfileAvatarComponent } from '@examdojo/user';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoPipe } from '@jsverse/transloco';
import { RootUrlParts } from '../app.model';
import { CmsUrlParts } from '../cms/cms.model';
import { SyllabusSelectComponent } from '../cms/syllabus/syllabus-select/syllabus-select.component';
import { IfUserHasRoleDirective } from '../user/user-role/if-user-has-role.directive';
import { UserQuery } from '../user/user.query';
import { LanguagePickerDialogService } from './language-picker-dialog/language-picker-dialog.service';

@Component({
  selector: 'exam-shell',
  imports: [
    RouterLink,
    IfUserHasRoleDirective,
    MatSidenav,
    MatSidenavContent,
    MatSidenavContainer,
    MatAnchor,
    IconComponent,
    MatMenuTrigger,
    ClassicButtonComponent,
    MatMenu,
    MatMenuItem,
    ProfileAvatarComponent,
    RouterOutlet,
    SyllabusSelectComponent,
    RouterLinkActive,
    NgTemplateOutlet,
    TranslocoPipe,
    FeatureFlagDirective,
  ],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  host: { class: 'h-full' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LanguagePickerDialogService],
})
export class ShellComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly userQuery: UserQuery,
    private readonly languagePickerDialogService: LanguagePickerDialogService,
  ) {
    provideFaIcons([faLanguage]);
  }

  readonly state = connectState({
    signedIn: this.authService.signedIn$,
    user: this.userQuery.active$,
  });

  readonly RootUrlParts = RootUrlParts;
  readonly CmsUrlParts = CmsUrlParts;
  readonly ExamDojoFeatureFlag = ExamDojoFeatureFlag;

  logout() {
    this.authService.signOut();
  }

  openLanguageDialog() {
    this.languagePickerDialogService.openDialog();
  }
}
