import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DebuggingWindowComponent } from '@examdojo/core/debugging';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag, ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { SyncQuestionStores } from '@examdojo/question';
import { IonApp } from '@ionic/angular/standalone';
import { register as registerSwiper } from 'swiper/element/bundle';
import { LanguageService } from './shared/i18n/language.service';

registerSwiper();

const APP_EFFECTS = [SyncQuestionStores];

@Component({
  selector: 'exam-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [IonApp, RouterOutlet, DebuggingWindowComponent],
  providers: [APP_EFFECTS],
})
export class AppComponent {
  constructor(private readonly languageService: LanguageService) {
    this.languageService.loadLanguageFromLocalStorage();
  }

  private readonly instantiatedServices = [FeatureFlagService<ExamdojoFeatureFlags>].map((service) => inject(service));
  private readonly effects = APP_EFFECTS.map((effect) => inject(effect));

  protected readonly ExamDojoFeatureFlag = ExamDojoFeatureFlag;
}
