import { inject, provideAppInitializer } from '@angular/core';
import { AuthService } from '@examdojo/user';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { LanguageService } from './shared/i18n/language.service';

export const APP_INITIALIZER_PROVIDER = provideAppInitializer(async () => {
  const authService = inject(AuthService);
  const languageService = inject(LanguageService);
  const faIconLibrary = inject(FaIconLibrary);

  console.debug('[APP_INITIALIZER]: starting');

  console.debug('[APP_INITIALIZER]: loading user');

  try {
    faIconLibrary.addIconPacks(far);

    await authService.loadUser();

    languageService.loadLanguageFromLocalStorage();
  } catch (err) {
    console.error('[APP_INITIALIZER]: errored', err);
  }

  console.debug('[APP_INITIALIZER]: finished');
});
