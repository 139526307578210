import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatMenu, MatMenuContent, MatMenuTrigger } from '@angular/material/menu';
import { connectState } from '@examdojo/angular/util';
import { SyllabusQuery, SyllabusService, SyllabusStoreModel } from '@examdojo/category/v2';
import { FilterableItemsListComponent, FilterableListItemDirective } from '@examdojo/core/filterable-items-list';
import { LocalizedStringComponent } from '@examdojo/core/i18n';
import { IconComponent } from '@examdojo/core/icon';
import { Unwrap } from '@examdojo/core/typescript';
import { combineLatest, distinctUntilChanged, filter, map, merge, startWith, tap } from 'rxjs';

@Component({
  selector: 'exam-syllabus-select',
  standalone: true,
  imports: [
    FilterableItemsListComponent,
    FilterableListItemDirective,
    IconComponent,
    MatMenu,
    MatMenuContent,
    MatMenuTrigger,
    LocalizedStringComponent,
    MatButton,
  ],
  templateUrl: './syllabus-select.component.html',
  styleUrl: './syllabus-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyllabusSelectComponent {
  constructor(
    private readonly syllabusQuery: SyllabusQuery,
    private readonly syllabusService: SyllabusService,
  ) {
    merge(this.setOptions(), this.setInitialValue(), this.setActiveSyllabusOnChange())
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  readonly options = signal<SyllabusStoreModel[] | null>(null);

  readonly syllabusIdCtrl = new FormControl<SyllabusStoreModel['id'] | null>(null);

  readonly state = connectState({
    selectedOption: combineLatest([
      this.syllabusIdCtrl.valueChanges.pipe(startWith(this.syllabusIdCtrl.value)),
      toObservable(this.options),
    ]).pipe(map(([value, options]) => options?.find((option) => option.id === value))),
  });

  readonly filterPropertyValueFn = (item: NonNullable<Unwrap<typeof this.options>>[number]) => item.name.en!;
  readonly identityFn = (item: NonNullable<Unwrap<typeof this.options>>[number]) => item.id.toString();

  setSelected(id: string) {
    this.syllabusIdCtrl.setValue(parseInt(id, 10));
  }

  private setOptions() {
    return this.syllabusQuery.entities$.pipe(
      filter((entities) => !!entities.length),
      tap((options) => this.options.set(options)),
    );
  }

  private setInitialValue() {
    return this.syllabusQuery.active$.pipe(
      filter(Boolean),
      tap((active) => this.syllabusIdCtrl.setValue(active.id)),
    );
  }

  private setActiveSyllabusOnChange() {
    return this.syllabusIdCtrl.valueChanges.pipe(
      distinctUntilChanged(),
      filter(Boolean),
      tap((id) => {
        this.syllabusService.setActive(id, 'HL');
      }),
    );
  }
}
