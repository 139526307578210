<div class="mx-auto flex h-full flex-col">
  <mat-sidenav-container class="shell-container h-full">
    <mat-sidenav [disableClose]="true" mode="side" [opened]="true">
      <div class="flex h-full flex-col">
        <div class="border-b border-solid border-gray-200">
          <exam-syllabus-select />
        </div>

        <div class="flex h-full grow flex-col items-start justify-between gap-4">
          <div class="flex w-full flex-col items-start gap-2 p-4">
            <ng-container *examIfUserHasRole="'CONTENT_CREATOR'">
              <ng-container
                *ngTemplateOutlet="
                  link;
                  context: { label: 'Questions', route: ['/', RootUrlParts.Cms, CmsUrlParts.Questions] }
                "
              />
              <ng-container
                *ngTemplateOutlet="
                  link;
                  context: {
                    label: 'Reference questions',
                    route: ['/', RootUrlParts.Cms, CmsUrlParts.ReferenceQuestions]
                  }
                "
              />
              <ng-container
                *ngTemplateOutlet="
                  link;
                  context: { label: 'Preview JSON', route: ['/', RootUrlParts.Cms, CmsUrlParts.PreviewFromJson] }
                "
              />
              <ng-container
                *ngTemplateOutlet="
                  link;
                  context: { label: 'Syllabus', route: ['/', RootUrlParts.Cms, CmsUrlParts.Syllabus] }
                "
              />
            </ng-container>
          </div>

          <div class="flex w-full flex-col items-start gap-2">
            @if (state.user) {
              <div class="w-full border-t border-t-gray-200 p-2">
                <dojo-classic-button
                  class="transparent-primary-button-no-border w-full"
                  [matMenuTriggerFor]="userMenu"
                  [class]="'w-full !justify-start !px-1 !py-1'"
                  [fullWidth]="true"
                >
                  <dojo-profile-avatar [size]="30" class="shrink-0" [user]="state.user" />
                  <div class="text-body-s menu-item-label ml-2 flex flex-col gap-0.5 truncate text-left">
                    <span class="dark:font-medium-dark truncate font-medium">{{ state.user.fullName }}</span>
                    <span class="text-typography-secondary dark:font-normal-dark truncate font-normal">
                      {{ state.user.email }}
                    </span>
                  </div>
                </dojo-classic-button>
              </div>
            }
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="h-full bg-white">
      <div class="scrollbar-gutter-stable h-full flex-1 overflow-y-auto p-4 pl-6 pr-4">
        <router-outlet />
        <ng-content />
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu #userMenu="matMenu">
  <button
    mat-menu-item
    (click)="openLanguageDialog()"
    data-test="user-top-nav-menu-button-user-settings"
    *ngIfFeatureFlag="ExamDojoFeatureFlag.Testing"
  >
    <dojo-icon class="text-typography-secondary" [size]="20" icon="language" />
    <span class="menu-item-label"> Language </span>
  </button>

  <button mat-menu-item (click)="logout()">
    <dojo-icon class="menu-icon" [icon]="'arrow-right-from-bracket'" />
    <span class="menu-item-label">{{ 'examdojo.registration.sign_out' | transloco }}</span>
  </button>
</mat-menu>

<ng-template #link let-label="label" let-route="route">
  <a
    mat-flat-button
    class="sidebar-link text-typography-secondary transparent-primary-button-no-border w-full !justify-start"
    [routerLink]="route"
    routerLinkActive="active"
  >
    <span class="text-typography-secondary">{{ label }}</span>
  </a>
</ng-template>
