import { Route } from '@angular/router';
import { authRoutes } from '@examdojo/auth';
import { NotFoundComponent } from '@examdojo/core/not-found';
import { RootUrlParts } from './app.model';
import { AuthGuard } from './auth/auth.guard';
import { syllabusesResolver } from './cms/syllabus/syllabus.resolver';
import { HomeComponent } from './home/home.component';
import { ShellComponent } from './shell/shell.component';
import { getUserRoleGuard } from './user/user-role/user-role.guard';

export const appRoutes: Route[] = [
  ...authRoutes,
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard],
    resolve: {
      syllabuses: syllabusesResolver,
    },
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: RootUrlParts.Cms,
        canActivate: [getUserRoleGuard('CONTENT_CREATOR')],
        loadChildren: () => import('./cms/cms.routes').then((m) => m.CMS_ROUTES),
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
