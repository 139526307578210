import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthUrlParts, REDIRECT_TO_PARAM } from '@examdojo/auth';
import { AuthService } from '@examdojo/user';
import { filter, map, of, switchMap, take } from 'rxjs';
import { UserQuery } from '../user/user.query';
import { UserService } from '../user/user.service';

export const AuthGuard: CanActivateFn = (_, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const userService = inject(UserService);
  const userQuery = inject(UserQuery);

  return authService.currentUser$.pipe(
    filter((val) => val !== null),
    take(1),
    switchMap((isAuthenticated) => {
      if (isAuthenticated) {
        return userQuery.getActive() ? of(true) : userService.fetchActiveUserProfile().pipe(map(() => true));
      }

      const queryParams = { [REDIRECT_TO_PARAM]: state.url };

      return of(
        router.createUrlTree([`/${AuthUrlParts.Login}`], {
          queryParams,
        }),
      );
    }),
  );
};
