import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SyllabusService } from '@examdojo/category/v2';
import { mapToVoid } from '@examdojo/rxjs';
import { first, tap } from 'rxjs';

export const syllabusesResolver: ResolveFn<void> = () => {
  const syllabusService = inject(SyllabusService);

  return syllabusService.fetchSyllabuses().pipe(
    first(),
    tap((syllabuses) => {
      if (!syllabuses.length) {
        return;
      }

      syllabusService.setActive(syllabuses[0].id, 'SL');
    }),
    mapToVoid(),
  );
};
