@if (options(); as options) {
  <button
    mat-button
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    class="top-nav-dropdown transparent-primary-button no-border w-full !justify-start truncate !rounded-none !px-4 !py-2"
  >
    <div class="flex w-full items-center">
      @if (state.selectedOption?.name; as name) {
        <dojo-localized-string [content]="name"></dojo-localized-string>
      }
      <dojo-icon [size]="12" icon="chevron-down" class="text-typography-secondary ml-auto" />
    </div>
  </button>

  <mat-menu #menu="matMenu" xPosition="before">
    <ng-template matMenuContent>
      <dojo-filterable-items-list
        [itemSize]="36"
        [items]="options"
        [selected]="syllabusIdCtrl.value ? [syllabusIdCtrl.value.toString()] : []"
        [identityFn]="identityFn"
        [filterPropertyValueFn]="filterPropertyValueFn"
        listMode="list"
        (selectItem)="setSelected($event); menuTrigger.closeMenu()"
        header="Syllabuses"
        searchPlaceholder="Search a syllabus"
      >
        <div *dojoListItem="options; let option" class="option-item">
          <dojo-localized-string [content]="option.name" />
          <dojo-icon [icon]="'check'" class="option-item-icon" />
        </div>
      </dojo-filterable-items-list>
    </ng-template>
  </mat-menu>
}
