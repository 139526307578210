import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withComponentInputBinding,
  withPreloading,
} from '@angular/router';
import { ExamdojoAuthService, provideAuthTokenInterceptor } from '@examdojo/auth';
import { provideFeatureFlag } from '@examdojo/core/feature-flag';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@examdojo/core/i18n';
import { MAT_TOOLTIP_DELAY } from '@examdojo/core/model';
import { provideToastConfig } from '@examdojo/core/toast';
import { provideExamdojoTranslations } from '@examdojo/i18n';
import { EXAMDOJO_FEATURE_FLAGS } from '@examdojo/models/feature-flag';
import { LayoutBreakpoint, provideLayoutBreakpoints } from '@examdojo/platform';
import { AuthService } from '@examdojo/user';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { provideMarkdown } from 'ngx-markdown';
import { APP_INITIALIZER_PROVIDER } from './app-initializer';
import { appRoutes } from './app.routes';
import { TranslocoHttpLoader } from './shared/i18n/transloco-loader';

export const SMALL_SCREEN_WIDTH = 768;
export const MEDIUM_SCREEN_WIDTH = 1000;
export const LARGE_SCREEN_WIDTH = 1400;

export const appConfig: ApplicationConfig = {
  providers: [
    APP_INITIALIZER_PROVIDER,
    provideAnimations(),
    provideMarkdown(),
    provideRouter(appRoutes, withPreloading(PreloadAllModules), withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(AngularSvgIconModule.forRoot()),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideToastConfig(),
    {
      provide: ExamdojoAuthService,
      useExisting: AuthService,
    },
    AuthService,
    provideTransloco({
      config: {
        availableLangs: SUPPORTED_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(),
    provideExamdojoTranslations(),
    provideIonicAngular(),
    provideAuthTokenInterceptor(),
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: MAT_TOOLTIP_DELAY,
      } satisfies Partial<MatTooltipDefaultOptions>,
    },
    provideFeatureFlag(EXAMDOJO_FEATURE_FLAGS),
    provideLayoutBreakpoints({
      [LayoutBreakpoint.Small]: SMALL_SCREEN_WIDTH,
      [LayoutBreakpoint.Medium]: MEDIUM_SCREEN_WIDTH,
      [LayoutBreakpoint.Large]: LARGE_SCREEN_WIDTH,
    }),
  ],
};
